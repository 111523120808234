import * as React from 'react';

import { graphql } from 'gatsby';

import Container from '../components/Container';
import Layout from '../components/Layout';
import RichText from '../components/RichText';

const AboutPage = ({ data }) => {
  const { title, body } = data.contentfulAbout;
  return (
    <Layout pagetitle={title} title={title}>
      <Container>
        <RichText
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
        />
      </Container>
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutQuery {
    contentfulAbout {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
